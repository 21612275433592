import React from "react";
import ProgramSearch from "app/components/Algolia/ProgramSearch";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useResetInitialPagination } from "hooks/useResetInitialPagination";
import { ResultsBodyWrapper } from "../ResultsBodyWrapper";

export default () => {
  const { resettingPagination } = useResetInitialPagination();

  if (resettingPagination) {
    return <LoaderCentered />;
  }

  return (
    <ResultsBodyWrapper
      contentTitle="Programs"
      contentType="programs"
      indexName={AlgoliaIndexes.programs}
    >
      <ProgramSearch />
    </ResultsBodyWrapper>
  );
};
